const mobileMenu = document.getElementById("mobileNav");
const mobileToggle = document.getElementById("mobileToggle");
const overlay = document.getElementById("mobileMenuOverlay");
const submenus = document.querySelectorAll(".sub-menu");
const parents = document.querySelectorAll(".menu-item-has-children");
const mobileMenuClose = mobileMenu.querySelector("#mobileMenuClose");

//console.log(parents)

// Open mobile navigation
const openMobileNav = (e) => {
  e.preventDefault();
  if (mobileMenu.classList.contains("open")) {
    mobileMenu.classList.remove("open");
    overlay.classList.remove("show");
  } else {
    mobileMenu.classList.add("open");
    overlay.classList.add("show");
  }
  //console.log('clicked')
};

mobileToggle.addEventListener("click", openMobileNav);

// toggle open on subs of parent menu items
const toggleParentMenu = (elem) => {
  console.log("Element",elem.srcElement.parentElement)
  let sub = elem.target.children[1];
  //console.log(sub)

  elem.stopPropagation()
  
  if (sub.classList.contains("open")) {
    console.log("Close All");
    // close all open subs
    closeSubs();
  } else {

    

    console.log("Open");
    const parent = elem.srcElement.parentElement

    if(!parent.classList.contains("sub-menu") && !parent.classList.contains("open")){
        // close all open subs first
        console.log("works!");
        closeSubs();
    }

    //closeSubs();

    // add open to clicked parent / sub
    sub.classList.add("open");
    elem.target.classList.add("open");
  }
  //console.log(sub)
};

parents.forEach((parent) => {
  parent.addEventListener("click", toggleParentMenu);
});

// close all sub menus when menu is closed
const closeSubs = () => {



  [].forEach.call(submenus, function (el) {
    el.classList.remove("open");
  });

  [].forEach.call(parents, function (par) {
    if (par.classList.contains("menu-item-has-children")) {
      par.classList.remove("open");
    }
  });
};

// close menu
const closeMobileMenu = () => {
  mobileMenu.classList.remove("open");
  overlay.classList.remove("show");
  closeSubs();
};

mobileMenuClose.addEventListener("click", closeMobileMenu);

// close menu on resize
window.addEventListener("resize", closeMobileMenu);
