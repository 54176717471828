if ($(window).width() > 1024) {
    var banner = $('.banner')
    if(banner) {
        banner.unslider({
            delay: 1000000
        });

        $('.unslider-arrow').click(function() {

            var fn = this.className.split(' ')[1];

            //  Either do unslider.data('unslider').next() or .prev() depending on the className

            unslider.data('unslider')[fn]();
        });
    }
} else {
    if(banner) {
        $('.banner').stop();
    }
}

if($('body').hasClass('home')) {
    var unslider2 = $('.banner2').unslider({
        dots: true
    });
} else {
    var unslider2 = $('.banner2').unslider({
        dots: false
    });
}

$('.unslider-arrow').click(function() {

    var fn = this.className.split(' ')[1];

    //  Either do unslider.data('unslider').next() or .prev() depending on the className

    unslider2.data('unslider2')[fn]();

});